@import '../../styles/Color.css';

.project-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 200px;
    gap: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 0;
    background-color: white;
    overflow: hidden;
    padding: 10px;
  }
  
  .project {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .newProject {
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    text-align: center;
  }

  .newProject:hover {
    background-color: var(--secondary);
  }


.field {
    display: flex; /* Align label and value horizontally */
    align-items: center;
    margin: 8px 0;
}

.label {
    font-weight: bold;
    margin-right: 5px; /* Space between label and value */
    color: #333;
    text-align: right; /* Align label text to the right */
}

.service-type,
.description {
    margin: 0; /* Reset default margins */
    color: #555; /* Optional: text color */
}