
@import './Color.css';

.App {
  display: flex;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100%;
  height: 100vh;
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 50;
}

.menu-item {
  display: block;
  padding: 10px 0;
  color: #333;
  text-decoration: none;
  text-align: left;
}

.menu-item:hover {
  color: #007BFF;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.page_container {
  position: 'absolute';
  left: 0;
  right: 0;
  top: 80;
  bottom: 0;
  color: 'blue';
  overflow: 'hidden';
}


.map-container {
  flex:1;
  height: "91vh";
  top: 0;
}


button {
  width: 100%;
  background-color: var(--primary);
  border: none;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 0px;
}

button:hover {
  background-color: var(--primary-hover);
}


button.red {
  width: 100%;
  background-color: var(--danger);
  border: none;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 0px;
}

button.red:hover {
  background-color: var(--danger-hover);
}





button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}


input[type="submit"]  {
  width: 100%;
  background-color: var(--primary);
  border: none;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 0px;
}

input[type="submit"]:hover {
  background-color: var(--secondary);
}


input {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 2px solid #ccc;
  cursor: pointer;
  margin: 5px;
}

.slideToggle {
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  max-height: 0;
}

.slideToggle.open {
  max-height: 500px; /* You may need to adjust this */
}

p.error{
  color: var(--danger);
  font-size: 14px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

.profile-details-container {
  margin: 0 auto;
  padding: 20px;

}

textarea {
  height: 150px;
  resize: none; /* Prevent resizing */
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
}